import Spline from '@splinetool/react-spline';
import React from 'react';

export default function Game() {
    return (
        <React.Fragment>
            <Spline
                scene="https://prod.spline.design/AlCBRWk1nLFsH3xZ/scene.splinecode"
            />
        </React.Fragment>
    );
}
