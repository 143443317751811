import { Route, Routes } from 'react-router-dom';
import Admin from './Admin';
import Game from './Game';
import Home from "./Home";

export const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Game />} />
        <Route path="/stake" element={<Home />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="*" element={<h1>404: Page Not Found</h1>} />
      </Routes>
    </>
  );
};
