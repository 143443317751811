import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import useNotification from '../utilities/notificationUtils';
import { useSDK } from '@metamask/sdk-react';

interface WalletContextType {
    account: string | undefined;
    chainId: string | undefined;
    connected: boolean;
    connecting: boolean;
    connect: () => Promise<void>;
    disconnect: () => Promise<void>;
}

const WalletContext = createContext<WalletContextType | undefined>(undefined);

const SANKO_CHAIN_ID = '0x7cc';
// const SANKO_CHAIN_ID = '0x4268';

export const WalletProvider = ({ children }: { children: ReactNode }) => {
    const { sdk, connecting, provider, chainId } = useSDK();
    const { showSuccess, showInfo, showError } = useNotification();
    const [account, setAccount] = useState<string | undefined>();
    const [connected, setConnected] = useState(false);

    // Fetch account details if provider is available
    useEffect(() => {
        const fetchAccount = async () => {
            if (provider) {
                try {
                    const accounts = await provider.request({ method: 'eth_accounts' }) as string[];
                    if (accounts.length > 0) {
                        setAccount(accounts[0]);
                        setConnected(true);

                        // Check if the connected network is Sanko
                        const currentChainId = await provider.request({ method: 'eth_chainId' }) as string;
                        if (currentChainId !== SANKO_CHAIN_ID) {
                            showError('Please switch to the Sanko network (chainId: 1996)');
                        }
                    } else {
                        setConnected(false);
                        setAccount(undefined);
                    }
                } catch (err) {
                    setConnected(false);
                    console.warn('Failed to fetch account', err);
                    showError('Failed to fetch account');
                }
            } else {
                setConnected(false);
                console.warn('Provider is not available');
            }
        };

        fetchAccount();
    }, [provider, showError]);

    // Handle disconnect event
    useEffect(() => {
        if (provider) {
            const handleDisconnect = () => {
                console.warn('Wallet disconnected');
                setAccount(undefined);
                setConnected(false);
                showInfo('Wallet disconnected');
            };

            provider.on('disconnect', handleDisconnect);

            // Cleanup listener on component unmount
            return () => {
                provider.removeListener('disconnect', handleDisconnect);
            };
        }
    }, [provider, showInfo]);

    // Connect function to connect to the wallet
    const connect = async () => {
        try {
            const accounts = await sdk?.connect() as string[] | null;
            if (accounts && accounts.length > 0) {
                setAccount(accounts[0]);
                setConnected(true);

                // Check if connected to the Sanko chain
                const currentChainId = await provider?.request({ method: 'eth_chainId' }) as string;
                if (currentChainId === SANKO_CHAIN_ID) {
                    showSuccess('Connected successfully to Sanko chain');
                } else {
                    showError('Please switch to the Sanko network (chainId: 1996)');
                }
            } else {
                setConnected(false);
                showError('No accounts connected');
            }
        } catch (err) {
            setConnected(false);
            console.warn('Failed to connect', err);
            showError('Failed to connect');
        }
    };

    // Disconnect function to manually disconnect the wallet
    const disconnect = async () => {
        try {
            await sdk?.terminate();
            setAccount(undefined);
            setConnected(false);
            showInfo('Disconnected successfully');
        } catch (err) {
            console.warn('Failed to disconnect', err);
            showError('Failed to disconnect');
        }
    };

    return (
        <WalletContext.Provider value={{ account, chainId, connected, connecting, connect, disconnect }}>
            {children}
        </WalletContext.Provider>
    );
};

// Custom hook to use the wallet context
export const useWallet = () => {
    const context = useContext(WalletContext);
    if (!context) {
        throw new Error('useWallet must be used within a WalletProvider');
    }
    return context;
};
