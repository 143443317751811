import * as ethers from "ethers";
import { nftContractConfig, stakePoolContractConfig, tokenContractConfig } from "../config";

const getTokenSmartContract = async () => {
    try {
        if (!window?.ethereum) {
            throw Error("Please install Metamask!");
        }
        const ethersProvider = new ethers.BrowserProvider(window?.ethereum);
        const signer = await ethersProvider.provider.getSigner();
        const tokenContract = new ethers.Contract(tokenContractConfig.contractAddress, tokenContractConfig.abi, signer);
        return tokenContract;
    } catch (e) {
        throw e;
    }
}

const getNFTSmartContract = async () => {
    try {
        if (!window?.ethereum) {
            throw Error("Please install Metamask!");
        }
        const ethersProvider = new ethers.BrowserProvider(window?.ethereum);
        const signer = await ethersProvider.provider.getSigner();
        const nftContract = new ethers.Contract(nftContractConfig.contractAddress, nftContractConfig.abi, signer);
        return nftContract;
    } catch (e) {
        throw e;
    }
}

const getStakeSmartContract = async () => {
    try {
        if (!window?.ethereum) {
            throw Error("Please install Metamask!");
        }
        const ethersProvider = new ethers.BrowserProvider(window?.ethereum);
        const signer = await ethersProvider.provider.getSigner();
        const tokenContract = new ethers.Contract(stakePoolContractConfig.contractAddress, stakePoolContractConfig.abi, signer);
        return tokenContract;
    } catch (e) {
        throw e;
    }
}

const getStakeSmartContractWithoutSigner = async (network: number) => {
    try {
        if (!window?.ethereum) {
            throw Error("Please install Metamask!");
        }
        const ethersProvider = new ethers.BrowserProvider(window.ethereum);
        const tokenContract = new ethers.Contract(stakePoolContractConfig.contractAddress, stakePoolContractConfig.abi, ethersProvider.provider);
        return tokenContract;
    } catch (e) {
        throw e;
    }
}

export { getStakeSmartContract, getStakeSmartContractWithoutSigner, getTokenSmartContract, getNFTSmartContract };
