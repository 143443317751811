import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import { AddStake } from './components';
import { tokenContractConfig } from './config';
import { useLoader } from './context/LoaderContext';
import { useWallet } from './context/WalletContext';
import { getStakeSmartContract } from './shared';
import { SECONDS_IN_A_DAY, SECONDS_IN_A_MINUTE, SECONDS_IN_AN_HOUR, secondsToDays } from './shared/secondsToDays';
import { handleMetaMaskError } from './utilities/handleMetaMaskError';
import useNotification from './utilities/notificationUtils';

interface Stake {
    id: number;
    amount: number;
    lockDuration: string;
    timeLeft: number; // Change from string to number for countdown
}

export default function Home() {
    const [open, setOpen] = useState<boolean>(true);
    const [stakes, setStakes] = useState<Stake[]>([]);
    const [reward, setReward] = useState<number>(0);
    const { showError, showSuccess, showInfo } = useNotification();
    const { showLoader, hideLoader } = useLoader();

    const handleClose = () => {
        setOpen(false);
    };

    const { account, connected, connecting, connect, disconnect } = useWallet();

    const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'g' || event.key === 'G') {
            setOpen(prev => !prev);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    // Function to handle adding a stake
    const handleAddStake = (amount: number, lockDuration: string) => {
        init();
    };

    // Function to handle stake withdrawal
    const handleWithdrawStake = async (stakeId: number) => {
        try {
            showLoader();
            const contract = await getStakeSmartContract();
            const tx = await contract.withdrawStake(stakeId);
            await tx.wait();
            showSuccess(`You have successfully claimed your rewards`);
            init();
        } catch (error: any) {
            showError(handleMetaMaskError(error));
        } finally {
            hideLoader();
        }
    };

    // Helper function to shorten the wallet address
    const shortenAddress = (address: string) => {
        return `${address.slice(0, 8)}...${address.slice(-5)}`;
    };

    const init = async () => {
        try {
            showLoader();
            const contract = await getStakeSmartContract();

            try {
                const totalAvailableRewards = await contract.getTotalAvailableRewards(account);
                console.log(totalAvailableRewards);

                setReward(Number(ethers.formatUnits(totalAvailableRewards, tokenContractConfig.decimals)));
            } catch (error) {
                console.error("Fetching getTotalAvailableRewards", error);
            }

            try {
                const userStakes = await contract.getUserStakes(account);
                const newStakes = userStakes?.map((single: any, i: number) => {
                    const unlockTime = Number(single.depositTime) + Number(single.lockPeriod);
                    const timeLeft = unlockTime - Math.floor(Date.now() / 1000);

                    let lockDuration = '6 Months';
                    const days = secondsToDays(Number(single.lockPeriod));
                    if (days === 14) {
                        lockDuration = '2 Weeks';
                    } else if (days === 30) {
                        lockDuration = '1 Month';
                    } else if (days === 90) {
                        lockDuration = '3 Months';
                    }

                    return {
                        id: i,
                        amount: ethers.formatUnits(single.amount, tokenContractConfig.decimals),
                        lockDuration,
                        timeLeft
                    };
                });
                setStakes(newStakes);
            } catch (error) {
                console.error("Fetching getUserStakes", error);
            }


        } catch (error: any) {
            showError(handleMetaMaskError(error));
        } finally {
            hideLoader();
        }
    };

    // Handle reward claiming
    const handleClaimReward = async () => {
        try {
            showLoader();
            const contract = await getStakeSmartContract();
            const tx = await contract.claimReward();
            await tx.wait();
            showSuccess(`You have successfully claimed your rewards`);
            setReward(0);
        } catch (error: any) {
            showError(handleMetaMaskError(error));
        } finally {
            hideLoader();
        }
    };

    useEffect(() => {
        if (account) {
            init();
        }
    }, [account]);

    // Timer for updating stakes' time left
    useEffect(() => {
        const intervalId = setInterval(() => {
            setStakes(prevStakes => prevStakes.map(stake => {
                if (stake.timeLeft > 0) {
                    return {
                        ...stake,
                        timeLeft: stake.timeLeft - 1 // Decrease by one second
                    };
                }
                return stake; // Return stake as is if time is up
            }));
        }, 1000); // Update every second

        return () => clearInterval(intervalId); // Cleanup interval on unmount
    }, [stakes]); // Run this effect whenever stakes change

    const formatTimeLeft = (timeLeft: number) => {
        const daysLeft = Math.floor(timeLeft / SECONDS_IN_A_DAY);
        const hoursLeft = Math.floor((timeLeft % SECONDS_IN_A_DAY) / SECONDS_IN_AN_HOUR);
        const minutesLeft = Math.floor((timeLeft % SECONDS_IN_AN_HOUR) / SECONDS_IN_A_MINUTE);
        const secondsLeft = timeLeft % SECONDS_IN_A_MINUTE;

        const timeParts = [];
        if (daysLeft > 0) timeParts.push(`${daysLeft} Day${daysLeft > 1 ? 's' : ''}`);
        if (hoursLeft > 0) timeParts.push(`${hoursLeft} Hour${hoursLeft > 1 ? 's' : ''}`);
        if (minutesLeft > 0) timeParts.push(`${minutesLeft} Minute${minutesLeft > 1 ? 's' : ''}`);
        if (secondsLeft > 0) timeParts.push(`${secondsLeft} Second${secondsLeft > 1 ? 's' : ''}`);

        return timeParts.length > 0 ? timeParts.join(', ') + ' Left' : 'Time Up';
    };

    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={true}
                className='home-dialog'
                sx={{
                    '& .MuiDialog-paper': {
                        backgroundColor: '#EF5400',
                        borderRadius: '10px',
                        borderColor: '#EF5400',
                        borderStyle: 'outset',
                        borderWidth: '5px'
                    },
                }}
            >
                <DialogContent>
                    {connected ? (
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography variant="h5" gutterBottom style={{ 'marginBottom': '0px' }}>
                                Connected
                            </Typography>
                            <Typography variant="h5">
                                {shortenAddress(account ?? "")}
                            </Typography>

                            {/* Show Reward */}
                            <Typography variant="h6">Your Reward: {reward} {tokenContractConfig.symbol}</Typography>
                            <Typography variant="body2">
                                * Rewards pool is topped by $SP protocol revenue and APYs may vary
                            </Typography>

                            {/* Show User Stakes */}
                            <Typography variant="h6">Your Staked $SP</Typography>
                            {stakes.map(stake => (
                                <div key={stake.id} style={{ 'width': '100%' }}>
                                    <Box display="flex" justifyContent="space-between" width="100%">
                                        <Typography variant="body1" style={{ fontWeight: '500' }}>
                                            {stake.amount} {tokenContractConfig.symbol} ({stake.lockDuration})
                                        </Typography>
                                        <Button
                                            variant="contained"
                                            color="warning"
                                            onClick={() => handleWithdrawStake(stake.id)}
                                            style={{
                                                transition: 'background-color 0.3s ease',
                                            }}
                                            disabled={stake.timeLeft > 0} // Disable if time left
                                        >
                                            Withdraw
                                        </Button>
                                    </Box>
                                    <Typography variant="body2" align='right' style={{ marginBottom: '15px' }}>
                                        *{formatTimeLeft(stake.timeLeft)}
                                    </Typography>
                                </div>
                            ))}

                            {/* Add Stake Component */}
                            <AddStake onAddStake={handleAddStake} />

                            {/* Claim Reward */}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleClaimReward}
                                sx={{ width: '100%', marginBottom: '10px' }}
                                disabled={reward <= 0} // Disable if no reward
                            >
                                Claim Reward
                            </Button>

                            {/* Disconnect */}
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={disconnect}
                                sx={{ width: '100%' }}
                            >
                                Disconnect
                            </Button>
                        </Box>
                    ) : (
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Typography variant="h5" gutterBottom>
                                Connect Your Wallet
                            </Typography>
                            <Button variant="contained" onClick={connect} disabled={connecting}>
                                {connecting ? 'Connecting...' : 'Connect Wallet'}
                            </Button>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
