import { Button, TextField } from '@mui/material';
import { ethers } from 'ethers';
import React, { useState } from 'react';
import { stakePoolContractConfig, tokenContractConfig } from '../config';
import { useLoader } from '../context/LoaderContext';
import { getStakeSmartContract, getTokenSmartContract } from '../shared';
import useNotification from '../utilities/notificationUtils';
import { handleMetaMaskError } from '../utilities/handleMetaMaskError';

interface AddRewardProps {
    onAddReward: () => void;
}

const AddReward: React.FC<AddRewardProps> = ({ onAddReward }) => {
    const [stakeAmount, setStakeAmount] = useState<string>('');
    const { showLoader, hideLoader, setLoaderMessage } = useLoader();
    const { showError, showSuccess } = useNotification();


    const approveToken = async (amount: bigint) => {
        const tokenContract = await getTokenSmartContract();
        try {
            const tx = await tokenContract.approve(stakePoolContractConfig.contractAddress, amount);
            await tx.wait();
        } catch (error) {
            throw error;
        }
    };

    const handleAddReward = async () => {
        const stakeValue = parseFloat(stakeAmount);
        if (!isNaN(stakeValue) && stakeValue > 0) {
            try {
                showLoader();

                const stakeValueInUnits = ethers.parseUnits(stakeValue.toString(), tokenContractConfig.decimals);

                setLoaderMessage("Approve the transaction to continue");
                await approveToken(stakeValueInUnits);
                setLoaderMessage(`Transaction approved, going to add ${tokenContractConfig.symbol} to reward pool`);
                const contract = await getStakeSmartContract();
                const tx = await contract.depositReward(
                    stakeValueInUnits
                );
                await tx.wait();
                showSuccess(`You have successfully added ${stakeValue} ${tokenContractConfig.symbol} to reward pool`);
                onAddReward();
                setStakeAmount('');
            } catch (error: any) {
                showError(handleMetaMaskError(error));
            } finally {
                setLoaderMessage("Loading...");
                hideLoader();
            }
        }
    };

    return (
        <>
            <TextField
                label="Enter Reward Pool Amount"
                value={stakeAmount}
                onChange={(e) => setStakeAmount(e.target.value)}
                type="number"
                variant="outlined"
                fullWidth
                style={{ marginBottom: '10px' }}
            />

            <Button
                variant="contained"
                color="primary"
                onClick={handleAddReward}
                sx={{ width: '100%' }}
                style={{ marginBottom: '10px' }}
            >
                Add to Pool
            </Button>
        </>
    );
};

export { AddReward };
