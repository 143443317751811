export const handleMetaMaskError = (error: any) => {
    // MetaMask Errors (info.error.code based)
    if (error?.info?.error?.code === 4001) {
        // User rejected the request
        return "User rejected the request. Please authorize the transaction.";
    } else if (error?.info?.error?.code === -32002) {
        // A request is pending (usually happens when the user has already a pending request)
        return "A request is pending. Please confirm the previous request.";
    } else if (error?.info?.error?.code === -32603) {
        // Internal JSON-RPC error (e.g., insufficient balance)
        if (error?.info?.error?.message?.includes('insufficient funds')) {
            return "Insufficient funds. Please ensure you have enough balance.";
        } else if (error?.message?.includes("execution reverted")) {
            // Smart Contract Revert with reason string
            const revertReason = extractRevertReason(error);
            return revertReason || "Smart contract execution reverted due to overflow. Please check your inputs or balance.";
        }
        return "An internal error occurred. Please try again.";
    }

    // Specific MetaMask error messages
    if (error?.info?.error?.message?.includes('user denied transaction signature')) {
        return "Transaction denied. Please authorize the transaction.";
    } else if (error?.info?.error?.message?.includes('missing provider')) {
        return "Ethereum provider not found. Please install MetaMask.";
    }

    // General Web3 Errors (error.message based)
    if (error?.message) {
        if (error?.message?.includes("invalid EIP-1193 provider")) {
            return "Ethereum provider not found. Please install MetaMask and try again.";
        } else if (error?.message?.includes("could not decode result data")) {
            return "Change your MetaMask network to Sanko chain or Arbitrum.";
        } else if (error?.message?.includes("execution reverted")) {
            // Smart Contract Revert with reason string
            const revertReason = extractRevertReason(error);
            return revertReason || "Smart contract execution reverted. Please check your inputs or balance.";
        } else if (error?.message?.includes("nonce too low")) {
            return "Transaction nonce is too low. Try increasing the gas or reset your nonce.";
        } else if (error?.message?.includes("gas required exceeds allowance")) {
            return "The gas required exceeds the allowance. Consider adjusting your gas limit.";
        } else if (error?.message?.includes("transaction underpriced")) {
            return "Gas price too low. Try increasing the gas price.";
        } else if (error?.message?.includes("transaction failed")) {
            return "Transaction failed. Ensure you have enough gas and check your inputs.";
        } else if (error?.message?.includes("revert")) {
            return "Transaction reverted by the EVM. Check the smart contract logic or your input values.";
        } else {
            return error?.message; // Generic error message
        }
    }

    // Return a general error message if no specific case is matched
    return "An unknown error occurred.";
};


// Helper function to extract revert reason from error object
const extractRevertReason = (error: any) => {
    // Check if the error has a revert field with args (common in errors returned from smart contracts)
    if (error?.revert?.args && error.revert.args.length > 0) {
        // Panic codes return a number indicating the type of error (e.g., OVERFLOW is Panic code 17)
        if (error.revert.args[0] == 17) {
            return "Smart contract execution reverted due to an overflow.";
        }
        return `Smart contract reverted with panic code ${error.revert.args[0]}.`;
    }

    // If revert reason is included in the message itself
    const revertReasonRegex = /execution reverted: (.+)/;
    const match = error?.message?.match(revertReasonRegex);
    return match ? match[1] : null;
};
