import React, { createContext, useState, useContext, ReactNode } from 'react';

// Define the context type
interface LoaderContextType {
    loading: boolean;
    message: string;
    showLoader: () => void;
    hideLoader: () => void;
    setLoaderMessage: (message: string) => void;
}

// Create the context with default values
const LoaderContext = createContext<LoaderContextType>({
    loading: false,
    message: "Loading...",
    showLoader: () => { },
    hideLoader: () => { },
    setLoaderMessage: (message) => { },
});

// Provide context to components
export const LoaderProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = React.useState('Loading...');

    const showLoader = () => setLoading(true);
    const hideLoader = () => setLoading(false);
    const setLoaderMessage = (message: string = "Loading...") => setMessage(message);

    return (
        <LoaderContext.Provider value={{ loading, message, showLoader, hideLoader, setLoaderMessage }}>
            {children}
        </LoaderContext.Provider>
    );
};

// Custom hook to use the loader context
export const useLoader = (): LoaderContextType => useContext(LoaderContext);
