import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useLoader } from '../context/LoaderContext';

const SimpleBackdrop: React.FC = () => {
    const { loading, message } = useLoader();

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999 }}
            open={loading}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column', // Stack items vertically
                    alignItems: 'center', // Center items horizontally
                }}
            >
                <CircularProgress color="inherit" />
                {/* Display the message below the loader */}
                {message.length > 0 && (
                    <Typography variant="body1" sx={{ mt: 2 }}> {/* Adjust margin as needed */}
                        {message}
                    </Typography>
                )}
            </Box>
        </Backdrop>
    );
};

export { SimpleBackdrop };
