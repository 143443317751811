import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import { AddReward } from './components';
import { tokenContractConfig } from './config';
import { useLoader } from './context/LoaderContext';
import { useWallet } from './context/WalletContext';
import { getStakeSmartContract } from './shared';
import useNotification from './utilities/notificationUtils';
import { handleMetaMaskError } from './utilities/handleMetaMaskError';


export default function Admin() {
    const [reward, setReward] = useState<number>(0);
    const { showError } = useNotification();
    const { showLoader, hideLoader } = useLoader();
    const { account, connected, connect, disconnect, connecting } = useWallet();

    const handleAddReward = () => {
        init();
    };

    const shortenAddress = (address: string) => {
        return `${address.slice(0, 8)}...${address.slice(-5)}`;
    };

    const init = async () => {
        try {
            showLoader();
            const contract = await getStakeSmartContract();
            const rewardPool = await contract.rewardPool();
            setReward(Number(ethers.formatUnits(rewardPool, tokenContractConfig.decimals)));
        } catch (error: any) {
            showError(handleMetaMaskError(error));
        } finally {
            hideLoader();
        }
    }

    useEffect(() => {
        if (account) {
            init();
        }
    }, [account]);

    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={true}
                className='home-dialog'
                sx={{
                    '& .MuiDialog-paper': {
                        backgroundColor: '#EF5400',
                        borderRadius: '10px',
                        borderColor: '#EF5400',
                        borderStyle: 'outset',
                        borderWidth: '5px'
                    },
                }}
            >
                <DialogContent>
                    {connected ? (
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography variant="h5" gutterBottom style={{ 'marginBottom': '0px' }}>
                                Connected
                            </Typography>
                            <Typography variant="h5">
                                {shortenAddress(account ?? "")}
                            </Typography>

                            <Typography variant="h6">Reward Pool: {reward} {tokenContractConfig.symbol}</Typography>

                            <AddReward onAddReward={handleAddReward} />

                            {/* Disconnect */}
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={disconnect}
                                sx={{ width: '100%' }}
                            >
                                Disconnect
                            </Button>
                        </Box>
                    ) : (
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{ gap: 2 }}
                        >
                            <Typography variant="h5" gutterBottom style={{ 'marginBottom': '0px' }}>
                                Connect your wallet to deposit reward
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={connect}
                                disabled={connecting}
                                sx={{ width: '100%' }}
                            >
                                {connecting ? 'Connecting...' : 'Connect Wallet'}
                            </Button>
                        </Box>
                    )}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
